<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">当前：生成盘点存货单v3</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="12" :md="3">
                                <el-date-picker
                                        v-model="pickerDateMonth"
                                        style=" margin-top: 8px;padding-top: 2px; width: 180px"
                                        type="month"
                                        align="right"
                                        size="mini"
                                        @change="$_pickerDateEvent(),searchChangeEvent()"
                                        :clearable="false"
                                        value-format="yyyy-MM-dd"
                                        unlink-panels
                                        range-separator="至"
                                        start-placeholder="开始月份"
                                        end-placeholder="结束月份">
                                </el-date-picker>
                                <el-date-picker
                                        v-show="false"
                                        style=" margin-top: 8px; width: 210px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        :disabled="false"
                                        size="mini"
                                        :picker-options="pickerOptions"
                                        value-format="yyyy-MM-dd"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>

                            <el-col :sm="12" :md="3">
                                <el-form-item label="仓库:">
                                    <el-select v-model="formData.warehouse_name" no-data-text="没有搜索结果!"
                                               size="mini"
                                               :placeholder="'全部'" default-first-option clearable
                                               @change="formData.warehouse = $event.id,formData.warehouse_name = $event.name,searchChangeEvent()"
                                               @clear="$_searcWarehouseList"
                                               @focus="$_searcWarehouseList" :filter-method="$_warehouseListFilter"
                                               filterable>
                                        <el-option v-for="item in $store.getters.getWarehouseList" :key="item.id" :label="item.name"
                                                   :value="item">
                                            <span style="margin:15px;">{{ item.name}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="14">
                                <el-button style=" margin-top: 8px; " type="primary" round @click="search()"
                                           size="mini">搜索
                                </el-button>
                                <el-button style=" margin-top: 8px; " :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           type="primary" round @click="openGoodsInOut($refs.xGrid.getCurrentRecord())"
                                           size="mini">查看产品进仓
                                </el-button>
                                <el-checkbox style="padding-left: 7px;padding-right: 7px" v-model="formData.showAverageWeight" @change="search()">以进仓重量进行核算(结存重量与期初重量会存在差异)</el-checkbox>
                                <el-button style=" margin-top: 8px; " type="primary" round @click="saveBuild()"
                                           size="mini">生成保存盘点单
                                </el-button>
                                <el-button type="success" style=" margin-top: 8px; " round
                                           @click="showAddStockDayBalance = true" size="mini">添加无发生额规格
                                </el-button>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <vxe-toolbar class="pull-left" style=" height: 42px; " custom export
                                             ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>

                        </el-row>
                    </el-col>
                </el-row>
                <vxe-modal v-model="showAddStockDayBalance" :lock-view="false" :mask="false" show-zoom resize
                           margin-size="-500" width="700" height="440" title="添加无发生额规格">
                    <template #default>
                        <el-row>
                            <el-col :sm="24" :md="24">
                                <el-tag type="danger" style="margin-bottom: 5px">填写模板：实际规格(空格或Tab)类别，每一行一个</el-tag>
                                <vxe-textarea v-model="addStockDayBalance" style="height: 300px" :placeholder="'100*50*2.5*6米 矩管\n100*80*3.5*12米 矩管'" resize="none"></vxe-textarea>
                                <el-button type="warning" @click="addStockDayBalanceEvent" round
                                           size="mini">确定
                                </el-button>
                            </el-col>
                        </el-row>
                    </template>
                </vxe-modal>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                id="toolbar_xGrid"
                                align="right"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                :cell-class-name="cellClassName"
                                highlight-hover-row
                                highlight-current-row
                                show-footer
                                :row-class-name="tableRowClassName"
                                :print-config="{}"
                                :export-config="{type: ['xlsx'] ,types: ['xlsx', 'csv', 'html', 'xml', 'txt'],isFooter:true,isColgroup:true}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :custom-config="{storage:true}"
                                @keydown="$_onGridKeydown"
                                :footer-method="footerMethod"
                                :mouse-config="{selected: true}"
                                :edit-config="{showIcon:false,trigger: 'dblclick', mode: 'cell', showStatus: false}"
                                :keyboard-config="{enterToTab:false,isArrow: true, isDel: false, isEnter: false, isTab: true,isEdit:true}">
                            <!-- 盘点数 -->
                            <template #count_py_edit="{ row}">
                                <vxe-input type="float" :controls="false"
                                           v-model="row.count_py"
                                           @change="count_py_stockEvent(row)"
                                           :disabled="isBlankVue(formData.warehouse)"
                                           clearable></vxe-input>
                            </template>

                            <!-- 支数 数量 提示  &&row.unit != '支' -->
                            <template #single_count_autocomplete_edit="{row}">
                                <vxe-pulldown ref="xDown" transfer>
                                    <template #default>
                                        <vxe-input v-model="row.count_py"  clearable suffix-icon="fa fa-search" :disabled="isBlankVue(formData.warehouse)"
                                                   @keyup="keyUpDropDownEvent($event)"
                                                   @focus="focusDropDownEvent({row})"
                                                   @blur="xDownIsShow=false"
                                                   @clear="row.batch_number = ''"
                                                   @change="count_py_stockEvent(row)"></vxe-input>
                                    </template>
                                    <!--$_singleCountEventCleandate(row),row.weighing_heavy ='',$_singleCountEventAndSumTopSome(row)-->
                                    <template #dropdown>
                                        <div class="my-dropdown4">
                                            <vxe-grid
                                                    ref="dropDownGrid"
                                                    align="left"
                                                    border
                                                    resizable
                                                    show-overflow
                                                    keep-source
                                                    auto-resize
                                                    size="mini"
                                                    height="320"
                                                    :radio-config="{highlight: true,trigger:'row'}"
                                                    :pager-config="dropDownTablePage"
                                                    :data="dropDownTableData"
                                                    :columns="dropDownTableColumn"
                                                    :cell-class-name="cellClassxDownSpecification"
                                                    @radio-change="radioChangeEvent"
                                                    @cell-dblclick="cellDblClickEvent"
                                                    @page-change="pageChangeEvent">
                                            </vxe-grid>
                                        </div>
                                    </template>
                                </vxe-pulldown>
                            </template>


                        </vxe-grid>
                    </el-col>

                </el-row>

            </el-form>
        </el-main>
    </div>
</template>

<script>

    import {filterRender} from "@/utils/gird-filter";

    import XEUtils from "xe-utils";

    export default {

        data() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            // 本月第一天
            var startDate = new Date(year + '-' + month + '-01 00:00:00');
            return {
                showAddStockDayBalance : false,
                addStockDayBalance:'',
                type:'',
                formData:{
                    warehouse:'',
                    warehouse_name:'',
                    showAverageWeight:true,
                },
                warehouseList: [],
                pickerDate: [startDate,new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')],
                pickerDateJC: ["2022-01-01",new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')],
                pickerDateMonth: new Date(),
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                },
                dropDownTableColumn: [
                    {type: 'radio', title: '选择', width: 40},
                    {field:'bill_type',title: '单据类型', width: 80},
                    {field:'batch_single_count',slots: {
                            // 使用 JSX 渲染
                            default: ({row}) => {
                                if (row.batch_single_count > 0) {
                                    return row.batch_single_count
                                }else{
                                    return [
                                        <el-tooltip class="item" effect="dark" placement="top">
                                        <div slot="content">
                                        查询条件：批号数 > 0 或者 批号等于该行原本批号
                                    <br/> 负数：假设这行出了100支，仓库是变成了负数，当“更新单据”查询的是该批号所以会显示负数
                                    </div>
                                    <i class="el-icon-info" style=" width: 1em; height: 1em; "> {row.batch_single_count}</i>
                                        </el-tooltip>
                                ]
                                }
                            }
                        },
                        titleHelp: {message: '查询条件：批号数 > 0 或者 批号等于该行原本批号'},  title: '仓库批次可用数', width: 55},
                    {field:'is_order', title: '是否订货', width: 50},
                    {field:'batch_number', title: '批号', width: 140},
                    {field:'supplier', title: '供应商', width: 130},
                    {field:'category', title: '类别', width: 60},
                    {field:'full_thick', title: '足厚', width: 50},
                    {field:'single_weight', title: '支重', width: 80},
                    {field:'warehouse', title: '仓库', width: 50},
                    {field:'remark_stock', title: '进货单备注', width: 130},
                    {
                        field:'bill_date', title: '日期', width: 80,
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yy-MM-dd')
                        }
                    },
                    {field:'tax_tate_stock', title: '产品税率', width: 80, formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                            }else{
                                return cellValue + '%'
                            }
                        }
                    },
                    {field:'specification', title: '规格', width: 130},
                ],
                dropDownTableData: [],
                dropDownTablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 50,
                    pageSizes: [50, 100, 200, 500]
                },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                            }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                        }
                    }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                            }
                            if (month < 10) {
                                month = '0' + month;
                            }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                        }
                    }]
                },

                tableColumn: [
                    {type: 'seq',fixed: 'left', title: '序号', width: 40},
                    {
                        field:'specification',fixed: 'left', width: 160, title: '规格', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'category',fixed: 'left', width: 80, title: '类别', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                        }
                    }, {
                        field:'before_count', width: 80, title: '期初支数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'before_weighing_heavy_total', width: 100, title: '期初重量', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 5})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                        }
                    }, {
                        field:'before_weighing_heavy_total_org', visible:false,width: 100, title: '期初重量2', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 5})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                        }
                    },{
                        title: '本期合计',
                        children: [
                            {
                                field:'now_single_count_total_jc', width: 80, title: '进仓支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                }
                            }, {
                                field:'now_weighing_heavy_total_jc', width: 100, title: '进仓重量', formatter: ({cellValue}) => {
                                    return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 5})
                                }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                }
                            }, {
                                field:'now_single_count_total_cc', width: 80, title: '出仓支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                }
                            }, {
                                field:'now_weighing_heavy_total_cc', width: 100, title: '出仓重量', formatter: ({cellValue}) => {
                                    return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 5})
                                }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                }
                            }, {
                                field:'now_weighing_heavy_total_cc_org',visible:false, width: 100, title: '出仓重量2', formatter: ({cellValue}) => {
                                    return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 5})
                                }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                }
                            },
                        ]
                    },
                    {title: '本期结存',
                        children: [
                            {
                                field:'balance_single_count', visible:false, width: 80, title: '结存支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                }
                            },{
                                field:'balance_weighing_heavy_total',visible:false,  width: 100, title: '结存重量', formatter: ({cellValue}) => {
                                    return this.$XEUtils.commafy(cellValue ? cellValue : '0', { digits: 5})
                                }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                }
                            },{
                                field:'balance_single_heavy',visible:false,  width: 100, title: '结存支重', formatter: ({cellValue}) => {
                                    return this.$XEUtils.commafy(cellValue ? cellValue : '0', { digits: 5})
                                }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                }
                            },
                            {
                                field:'balance_weighing_heavy_total_org',visible:false, width: 100, title: '结存重量2', formatter: ({cellValue}) => {
                                    return this.$XEUtils.commafy(cellValue ? cellValue : '0', { digits: 5})
                                }, slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                }
                            },
                        ]
                    },
                    {
                        title: '本期进出明细',
                        children: [

                            {
                                title: '盘点明细',
                                children: [
                                    {
                                        field:'now_single_count_jc_py',visible:false,  width: 105, title: '盘点支数', slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                        }
                                    },  {
                                        field:'now_naked_price_jc_py',visible:false,  width: 105, title: '盘点金额', formatter: ({cellValue}) => {
                                            return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 2})
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                        }
                                    },{
                                        field:'now_weighing_heavy_jc_py',visible:false,  width: 105, title: '盘点重量', formatter: ({cellValue}) => {
                                            return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 5})
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                        }
                                    },
                                ]
                            },
                            {
                                title: '每支成本明细',
                                children: [
                                    {
                                        field: 'finished_cost', visible:false, width: 140, title: '每支加工出仓成本',
                                        titleHelp: {message: '加工出仓成本= (期初金额+本期外购进仓金额+\'否\'合计单据上出仓金额)/(期初支数+本期外购进仓支数+\'否\'合计单据上出仓金额)'},
                                        formatter: ({cellValue}) => {
                                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                        }
                                    }, {
                                        field: 'kd_cost',visible:false,  width: 115, title: '每支销售成本',
                                        titleHelp: {message: '销售成本= (期初金额+本期外购进仓金额+本期加工进仓金额+本期盘点进仓金额)/(期初支数+本期外购进仓支数+本期加工进仓支数+本期盘点进仓支数)'},
                                        formatter: ({cellValue}) => {
                                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                                        }, slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                        }
                                    },  {
                                        field: 'inventory_cost',
                                        width: 105,
                                        title: '每支盘点成本',
                                        formatter: ({cellValue}) => {
                                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                                        },
                                        slots: {
                                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                                        }
                                    }
                                ]
                            }
                        ]
                    },{
                        field:'count_py',width: 85, title: '盘点数',
                        slots: {
                            edit: 'single_count_autocomplete_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                        },
                        editRender: {
                            autofocus: 'input.vxe-input--inner'
                        }
                    },{
                        field:'single_count_org',width: 85, title: '原库存数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/createInventoryList/searchHeard', this.pickerDate)
                        }
                    },{
                        field:'build_count', width: 100, title: '将要生成的盘点支数'
                    },{
                        field:'batch_number', width: 100, title: '批号'
                    },
                ],
                tableDataMain: [],
                filterData: {},
                filterDataJC: {},
                sortData: {
                    'category':'asc',
                    'specification':'asc'
                },
                sortData2:{"bill_date": "desc"}
            }
        },
        methods: {
            pageChangeEvent({currentPage, pageSize}) {
                this.dropDownTablePage.currentPage = currentPage
                this.dropDownTablePage.pageSize = pageSize
                const activeRow = this.$refs.xGrid.getActiveRecord().row;
                this.searchJCWarehouse(activeRow);
            },
            cellDblClickEvent() {
                //console.log(row.single_weight);
                this.$refs.xDown.hidePanel()
            },
            radioChangeEvent({row}) {
                this.radioChangeEnterEvent(row);
            },
            radioChangeEnterEvent(row) {
                //console.log('row.weighingHeavy',row);
                if (row != null){
                    const activeRow = this.$refs.xGrid.getActiveRecord().row;
                    //activeRow.weighing_heavy = this.$XEUtils.divide(this.$XEUtils.multiply(activeRow.single_count , row.single_weight), 1000);
                    //取消仓库跟着批号变动
                    //if (activeRow.warehouse_out_id == null || activeRow.warehouse_out_id == ''){
                   /* activeRow.warehouse_out = row.warehouse;
                    activeRow.warehouse_out_id = row.warehouse_id;*/
                    //}
                    //换成单才会读取 对应的足厚、理计
/*                    if (this.searchType == 'ReplaceWarehouseBill'){
                        activeRow.remark_stock = row.remark_stock;
                        activeRow.theory_weight = row.theory_weight;
                    }*/
                    activeRow.full_thick = row.full_thick;
                    activeRow.single_weight = this.$XEUtils.divide(row.single_weight,1000);
                    activeRow.batch_number = row.batch_number;
                    activeRow.single_count = row.build_count;
                    console.log("activeRow.single_count",activeRow.build_count);
                    activeRow.weighing_heavy = this.$XEUtils.round(this.$XEUtils.divide(this.$XEUtils.multiply(activeRow.single_count , activeRow.single_weight), 1000),6);
                    //根据批号查询税率
                    /*                    console.log('remark_warehouse_bill:' + this.formData.remark_warehouse_bill)
                                        console.log('remark_warehouse_bill:' + this.$parent.formData)
                                        console.log('remark_warehouse_bill:' + this.$parent.$parent.formData)
                                        console.log('remark_warehouse_bill:' + this.$parent.$parent.$parent.formData)
                                        console.log('remark_warehouse_bill:' + this.$parent.$parent.$parent.$parent.formData)
                                        console.log('remark_warehouse_bill:' + this.$parent.$parent.$parent.$parent.$parent.formData)
                                        console.log('remark_warehouse_bill:' + this.$parent.$parent.$parent.$parent.$parent.$parent.formData)*/
/*                    if (this.$parent.$parent.$parent.$parent.$parent.pageType =='销售开单' && !this.isBlankVue(this.$parent.$parent.$parent.$parent.$parent.formData.remark_warehouse_bill)){
                        this.$_batchNumberGetSupperTax(this.$parent.$parent.$parent.$parent.$parent.formData.remark_warehouse_bill,activeRow);
                    }*/
                   // this.$_singleCountEventEvery();
                }
                this.$refs['xDown'].hidePanel();
                this.xDownIsShow = false
            },
            cellClassxDownSpecification({row,column}){
                if (column.property == 'batch_single_count' && row != null) {
                    if (row.batch_single_count <= 0) {
                        return 'col-yellow'
                    }
                }
            },
            keyUpDropDownEvent(event) {
                this.filterData.bill_id_type = 'JC'
                if (this.xDownIsShow == false){
                    this.xDownIsShow = true;
                    this.$refs['xDown'].showPanel();
                }
                let i = 0;
                if (event.$event.code == 'ArrowUp' || event.$event.code == 'ArrowDown') {
                    if (this.$refs.dropDownGrid.getRadioRecord() != null) {
                        i = this.$refs.dropDownGrid.getRowIndex(this.$refs.dropDownGrid.getRadioRecord());
                        if (event.$event.code == 'ArrowUp') {
                            if (i > 0) {
                                i = i - 1;
                            }
                        } else if (event.$event.code == 'ArrowDown') {
                            i = i + 1;
                        }
                    }
                    //console.log("i: " + i)
                    this.$refs.dropDownGrid.setRadioRow(this.dropDownTableData[i]);
                    this.$refs.dropDownGrid.scrollToRow(this.dropDownTableData[i]);
                }
                //暂时不需要
                else if(event.$event.code == 'Enter' || event.$event.code == 'NumpadEnter'){
                    //如果有内容，则回车默认选中第一个值
                    //console.log('radioChangeEnterEvent回车');
                    this.radioChangeEnterEvent(this.$refs.dropDownGrid.getRadioRecord());
                }
            },
            focusDropDownEventGrid(row){
                this.focusDropDownEvent({row});
            },
            focusDropDownEvent({row}) {
                this.filterDataJC.bill_id_type = 'JC'
                //console.log(row.single_count_temp)
                this.searchJCWarehouse(row);
                this.$refs.xDown.showPanel();
                this.xDownIsShow=true;
            },
            async searchJCWarehouse(row) {
                this.dropDownTableData = []
                if (!this.isBlankVue(row.specification)) {
                    this.filterDataJC.specification = row.specification;
                    this.filterDataJC.category = row.category;
                    await this.$axios({
                        method: 'POST',
                        url: '/admin/allWarehouse/search',
                        data: {
                            currentPage: this.dropDownTablePage.currentPage,
                            pageSize: this.dropDownTablePage.pageSize,
                            sortData: this.sortData2,
                            filterData: this.filterDataJC,
                            pickerDate: this.pickerDateJC,
                            searchType: this.searchType,
                            singleCount:row.single_count,//负数的话，显示所有规格批号
                            warehouse_out_id:row.warehouse_out_id,//仓库，新仓，可以显示负数的批号
                            searchBatchNumber: true,
                            searchBatchNumberRow: row.batch_number,
                            isAccurate:true
                        }
                    }).then((response) => {          //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        if (response.status === 200) {
                            this.dropDownTableData = response.data.page.list
                            this.dropDownTablePage.pageSize = response.data.page.pageSize
                            this.dropDownTablePage.total = response.data.page.totalRow
                            this.dropDownTablePage.currentPage = response.data.page.pageNumber
                        }
                    }).catch((error) => {
                        console.log(error)
                    });
                }

                /*if (!this.isBlankVue(specification)) {
                    this.filterData.specification = specification;
                    if (!this.isBlankVue(category)) {
                        this.filterData.category = category;
                   }else{
                        delete this.filterData.category;//删除category元素 删除对象属性
                   }

               } else {
                    this.dropDownTableData = []
               }*/
            },
            saveBuild(){
                const loading = this.$loading({
                    lock: true,
                    text: '数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios({
                    method: 'POST',
                    url: '/admin/createInventoryList/saveBuild',
                    data: {
                        pickerDate: this.pickerDate,
                        formData:this.formData,
                        tableDataMain:this.$refs.xGrid.getTableData().fullData//获取表格所有数据，包括新插入的
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.data.state == 'fail'){
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }else if (response.status === 200) {
                        this.search();
                       // this.only_bill_id = response.data.warehouseBill.only_bill_id;
                    }
                }).catch((error) => {
                    loading.close();
                    console.log(error)
                });
            },
            count_py_stockEvent(row){
                //将要生成的盘点支数 = 盘点数 - 结存支数
                row.build_count = this.$XEUtils.subtract(row.count_py,row.balance_single_count)
                //row.build_count = this.$XEUtils.subtract(this.$XEUtils.subtract(row.count_py,row.now_single_count_jc_py),row.single_count_org);
            },
            searchChangeEvent(){
                if(!this.isBlankVue(this.formData.warehouse)){
                    this.search();
                }
            },
            search() {
                this.changeTableColumn();
                this.tableDataMain = [];
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios({
                    method: 'POST',
                    url: '/admin/createInventoryList/search',
                    data: {
                        type:this.type,
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData:this.formData
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.data.state == 'fail'){
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }else if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                    }
                }).catch((error) => {
                    loading.close();
                    console.log(error)
                });
                if(this.formData.showAverageWeight){
                    this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('before_weighing_heavy_total'))
                    this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('now_weighing_heavy_total_cc'))
                    //this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('balance_weighing_heavy_total'))
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('before_weighing_heavy_total_org'))
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('now_weighing_heavy_total_cc_org'))
                    //this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('balance_weighing_heavy_total_org'))
                }else{
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('before_weighing_heavy_total'))
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('now_weighing_heavy_total_cc'))
                    //this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('balance_weighing_heavy_total'))
                    this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('before_weighing_heavy_total_org'))
                    this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('now_weighing_heavy_total_cc_org'))
                    //this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('balance_weighing_heavy_total_org'))
                }

            },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.search();
            },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
                } else {
                    return '';
                }
            },
            cellClassName({column}) {
                if (column.property === 'count_py' && !this.isBlankVue(this.formData.warehouse)) {
                    return 'col-fail'
                }
            },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
                })
                return count;
            },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                    } else {
                        if (
                            column.property === 'before_count' || column.property === 'before_stock_amount' || column.property === 'before_weighing_heavy_total'
                            || column.property ===  'before_weighing_heavy_total_orig'
                            || column.property === 'now_single_count_total_jc' || column.property === 'now_naked_price_total_price_jc' || column.property === 'now_weighing_heavy_total_jc'
                            || column.property === 'now_single_count_total_cc' || column.property === 'now_naked_price_total_cc' || column.property === 'now_weighing_heavy_total_cc'
                            || column.property === 'now_weighing_heavy_total_cc_org'
                            || column.property === 'balance_single_count' || column.property === 'balance_naked_price' || column.property === 'balance_weighing_heavy_total'
                            || column.property === 'balance_weighing_heavy_total_org'
                        ) {
                            sums.push(this.sumNum(data, column.property))
                        } else {
                            sums.push('')
                        }
                    }
                })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
            },
            changeTableColumn(){
            },
            //打开产品进仓
            openGoodsInOut(row){
                const{href} = this.$router.resolve({path: '/goodsInOut', query: {warehouse_name:this.formData.warehouse_name,warehouse: this.formData.warehouse,category: row.category,specification:row.specification,pickerDate:[XEUtils.toDateString(this.pickerDate[0], 'yyyy-MM-dd'),XEUtils.toDateString(this.pickerDate[1], 'yyyy-MM-dd')]}});
                window.open(href,'_blank');
            },
            addStockDayBalanceEvent(){
                this.$axios({
                    method:'post',
                    url:'/admin/createInventoryList/addStockDayBalance',
                    data: {
                        addStockDayBalance:this.addStockDayBalance
                    }
                }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == 'ok'){
                        //判断能成功存进去的规格，直接插入前端表格第一行
                        //编列对象
                        //console.log(response.data.specificationList);
                        response.data.specificationList.forEach(item => {
                            this.$refs.xGrid.insert({
                                specification: item.specification_name,
                                category: item.category_name
                            })
                        });
                        this.showAddStockDayBalance = false;
                        this.addStockDayBalance = '';
                    }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) =>{
                    console.log(error)
                });
            },


        },computed: {
        },
        created() {
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                if (!this.isBlankVue(this.$route.query.type)){
                    this.type = this.$route.query.type;
                    /*                    this.formData.warehouse = this.$route.query.warehouse;
                                        this.pickerDateMonth = this.$route.query.pickerDateMonth;
                                        this.$_pickerDateEvent();*/
                    this.changeTableColumn();
                }else{
                    this.search();
                }
            })

        }

    };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
    }

    .el-form-item__content .el-input {
        width: 130px;
    }

    /deep/.vxe-table--render-default .vxe-header--column.col--right{
        text-align: left !important;
    }

    /deep/.my-domain.vxe-input {
        height: 34px;
        width: 300px;
    }
    /deep/.my-domain.vxe-input .vxe-input--prefix {
        width: 60px;
        height: 32px;
        top: 1px;
        text-align: center;
        border-right: 1px solid #dcdfe6;
        background-color: #f5f7fa;
    }
    /deep/.my-domain.vxe-input .vxe-input--inner {
        padding-left: 72px !important;
        border: 1px solid #dcdfe6;
    }
    /deep/.my-domain.vxe-input .vxe-input--suffix {
        width: 90px;
        height: 32px;
        top: 1px;
        text-align: center;
        border-left: 1px solid #dcdfe6;
        background-color: #f5f7fa;
        cursor: pointer;
    }
</style>
